(function($){

    setInterval(function(){
        $("#sc-no-breadcrumb").css({ 'height': $("#sc-left-right-content-wrapper").height() });
    },10);

    $("ul.menu-widget a").each(function(){
        //console.log('compare: ' + link_itself.attr('href') + ' with ' + window.location.href );
        if( $(this).attr('href') === window.location.href ){

            $( this ).toggleClass( "active" );
            const countDepth = (window.location.pathname.match(/\//g) || []).length;
            if ( countDepth == 5 ){
                $( this ).closest( '.current-menu-ancestor ' ).children().addClass( "parent-active" );
                let showItemsAround = $( this ).closest('li').children('ul').children();
                $( this ).closest('li').children('ul').children();
                showItemsAround.each(function(){
                    $(this).css("display", "block");
                });
            }
            
            if ( countDepth >= 4 ){
                
                $( this ).closest( '.current-menu-ancestor ' ).children().addClass( "parent-active" );
                let showItemsAround = $( this ).closest( '.current-menu-ancestor ' ).children().children();
                showItemsAround.each(function(){
                    //$(this).css("display", "block");
                });
            }
            if ( countDepth == 3 ){
                let showChilds = $( this ).closest('li').children('ul').children();
                showChilds.each(function(){
                    //$(this).css("display", "block");
                });
            }
        }
    });

})(jQuery);

/**
 * Opens the parent URL on the mobile view of the left navigation
 * @param {element} element The clicked object. 
 * @param { string } submenu The submenu ID.
 */
function openParentLink(element, submenu)
{
    document.location = element.getAttribute('data-href');

    // hide submenu always
    const subMenu = document.getElementById(submenu);
    if (subMenu) {
        subMenu.style.height = 0;
        subMenu.style.display = 'none';
    }
}

/**
 * This code rewrites DOM for the new Left Nav on the mobile view affecting the EAEL Advanced Menu for Elementor that has the class .the-left-nav-menu
 */
document.addEventListener("DOMContentLoaded", function() {
    
    // Run only on small screens.
    if (document.documentElement.clientWidth <= 801) {  	 
        let NavLeftMenu = null;   
        NavLeftMenu = document.querySelector('.elementor-widget-eael-advanced-menu.the-left-nav-menu .elementor-widget-container');
        
        if(NavLeftMenu) {
            const InnerElements = NavLeftMenu.querySelector('.eael-advanced-menu-container');
            
            if (InnerElements) {            
                const MenuContainer = InnerElements.querySelector('.eael-advanced-menu');
                const MenuFirstChild = MenuContainer.firstElementChild;
                const MenuLastChild = MenuContainer.lastElementChild;
                
                if (MenuFirstChild) {
                    MenuFirstChild.classList.add('firstchild-border-radius');
                }
                
                if (MenuLastChild) {
                    MenuLastChild.classList.add('lastchild-border-radius');
                }
                
                if (MenuContainer) {
                    MenuContainer.classList.add('nav-menu-container')
                }
                
                let MenuItems = InnerElements.querySelectorAll('.eael-advanced-menu li a');
            
                ModifyMenuItems (MenuItems);
            }
                
            let intervalID; 
			let EmergencyStop = 0;
			
            if(!intervalID){
                intervalID = setInterval(CurrentActivePage, 200, InnerElements); 
            }

            /**
             * Fetch the current page to show it as active on the dropdown select.
             * @param {element} ParentMenu The menu container. 
             */
            function CurrentActivePage(ParentMenu) {          
                let ActivePageTxt;    
                let ActivePageBackupTxt;
				let ActivePageBackup;
				
                const ActivePage = ParentMenu.querySelector('.eael-advanced-menu-toggle-text');
                
                if(ActivePage) {
                	ActivePageTxt = ActivePage.textContent;
                }
            
                ActivePageBackup = document.querySelector('.the-left-nav-menu a.eael-item-active');
				
                if(ActivePageBackup && !ActivePageTxt) {
                	ActivePageBackupTxt = ActivePageBackup.textContent;
                }

                const DropdownButton = ParentMenu.querySelector('.eael-advanced-menu-toggle');
                
                if(!ActivePageTxt){
					
					// There isn't any active page on the left nav, taking the current parent page
					if(null == ActivePageBackupTxt) {
						ActivePageBackupTxt =  document.querySelector('.the-left-nav-menu  .current-page-ancestor a').textContent;
					}
					
                	ActivePageTxt = ActivePageBackupTxt;
                }

                if(ActivePageTxt && DropdownButton) {
					clearInterval(intervalID);	
                	ModifyNavMob (ActivePageTxt, DropdownButton);		
                }
				
                // If any error is triggered the interval will stop the 20th time.
				if (EmergencyStop == 20) {
					clearInterval(intervalID);
				} else {
					EmergencyStop++;
				}
            } 
    
            /**
             * Set the current page to show it as active on the dropdown select.
             * @param {string} Current The text fetched from the active page.
             * @param {element} Button The button object that will receive the text.
             */
            function ModifyNavMob (Current, Button) {
                let span = document.createElement('span');
                span.classList.add('current-mobile-selector-container');
                let div = document.createElement('div');
                div.textContent = Current;
                div.classList.add('current-mobile-selector');
                span.prepend(div);
                Button.prepend(span);
                Button.classList.add('toggle-button-mobile-nav');
                span.nextElementSibling.classList.add('current-mobile-selector-chevron');
                let IconSVG = span.nextElementSibling.firstChild;
                
                if (IconSVG) {
                    IconSVG.classList.add('current-mobile-selector-chevron-color');
                }
            }

            /**
             * Add CSS class that removes the border of each menu item on mobile.
             * @param {Array} List The menu items.
             */        
            function ModifyMenuItems (List) {              
                if(List) {
                    List.forEach(function (item) {
                    item.classList.add('eael-advanced-menu-vertical-no-bottom-border');
                    });  
                }
            }
        }
    } // Width Checker End
});   